import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import './style.css';
import classes from "./style.css"
import { Layout, Menu, Button, Modal} from 'antd';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import Upload from "../Upload";
import { auth, db, logout } from "../../config/firebase";
import { doc, getDoc } from "firebase/firestore";
import Map from "../GeomanMap/GeomanMap";

import {
    UserOutlined,
    LogoutOutlined,
    ProfileOutlined,
    EditOutlined,
    UploadOutlined,
    DownloadOutlined,
} from '@ant-design/icons';


function Home(){
    const [isAdmin, setIsAdmin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [allowUpload, setAllowUpload] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmailAdress] = useState('');
    const [activeAgentId, setActiveAgentId] = useState('');
    const [mappingRole, setMappingRole] = useState('');


    const showModal = () => {
        setVisible(true);
    };

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setIsLoading(true);

        setTimeout(() => {
            setIsLoading(false);
            setVisible(false)
        }, 0);
    };

    const handleCancel = () => {
        setVisible(true);
    };

    const enableEditMode = () => {
        setEditMode(true);
        console.log("enableEditMode - EditMode:", editMode)
    };

    const disableEditMode = () => {
        setEditMode(false)
        console.log("disableEditMode - EditMode:", editMode)
    };

    const onCollapse = (collapsed) => {
        setCollapsed(collapsed);
    };

    const { SubMenu } = Menu;

    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();

    const { Content, Footer, Sider } = Layout;


    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/login");
    }, [user, loading]);

    const handleLogOut = () =>{
        logout();
    }

    const getUserDetails = async function(){
        auth.onAuthStateChanged(async currentUser => {
            if (currentUser) {
                const docRef = doc(db, "users", currentUser.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    console.log("Document data:", docSnap.data());
                    setIsAdmin(docSnap.data().isAdmin);
                    setAllowUpload(docSnap.data().allowUpload);
                    setName(docSnap.data().name);
                    setEmailAdress(docSnap.data().email);
                    setActiveAgentId(docSnap.data().activeAgentId);
                    setMappingRole(docSnap.data().bookingRol);
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }
        })
    }
    useEffect(() => {
        getUserDetails();

    });
    return (
        <Layout className="Layout" style={{ minHeight: '100vh' }}>
            <Sider className="element.style" collapsible collapsed={collapsed} onCollapse={onCollapse} width="225">
                <div className="logo"/>
                <Menu theme="dark" defaultSelectedKeys={['1']} defaultOpenKeys={['sub1']} mode="inline">
                    <SubMenu key="sub1" icon={<ProfileOutlined />} title="Geo Segmente">
                        <Menu.Item
                            onClick={() => {
                                showModal();
                                disableEditMode();
                            }}
                            key="1"
                        >{<UploadOutlined />} Segment Hochladen
                            <Modal
                                visible={visible}
                                title="Segment Hochladen"
                                onOk={handleOk}
                                onCancel={handleOk}
                                footer={[
                                    <Button className={classes.SubmitButton} key="submit" type="primary" loading={isLoading} onClick={handleOk}>
                                        Fertig
                                    </Button>,
                                ]}
                            >
                                <Upload/>
                            </Modal>

                        </Menu.Item>
                        <Menu.Item key="3" onClick={enableEditMode}>{<EditOutlined />} Segment Anlegen</Menu.Item>
                        <Menu.Item key="4" onClick={enableEditMode}>{<EditOutlined />} Segment Bearbeiten</Menu.Item>
                        <Menu.Item key="5" onClick={disableEditMode}>{<DownloadOutlined />} Segment Exportieren</Menu.Item>

                    </SubMenu>
                    <SubMenu key="sub2" icon={<UserOutlined />} title={name}>
                        <Menu.Item
                            onClick={() =>
                                Modal.info({
                                    title: 'Profilinformationen',
                                    content: (
                                        <div>
                                            <br/>
                                            <p>Name: {name}</p>
                                            <p>Email: {email}</p>
                                            <p>Active Agent ID: {activeAgentId}</p>
                                            <p>Rolle: {[mappingRole]}</p>
                                        </div>
                                    ),
                                    onOk() {},
                                })
                            }
                            key="6"
                        >{<ProfileOutlined />} Profil</Menu.Item>
                        <Menu.Item key="7" onClick={handleLogOut}>{<LogoutOutlined />} Abmelden</Menu.Item>
                    </SubMenu>
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <div className="inProgress">
                    <div className="inProgressText">Work in Progress</div>
                </div>
                <Content>
                    <Map userisAdmin={isAdmin} isEditModeEnabled={editMode} />
                </Content>
                <Footer className="Footer" style={{ textAlign: 'center' }}></Footer>
            </Layout>
        </Layout>
    );
}


export default Home;
