import React, {useState , useEffect} from 'react';
import { db, storage, ref } from '../../config/firebase';
import { collection, doc, setDoc } from "firebase/firestore";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { Button } from 'antd';
import classes from "./style.css"
import './style.css';

// Setup Firebase



// async function createNewGeoSegmentID() {
//     let newGeoSegmentID = 0;
//     var currentSegmentIDs = [];
//     var geoSegmentRef = database.collection("geo-segments");
//     await geoSegmentRef.where("Category", "==", "geo")
//     .get()
//     .then(querySnapshot => {
//         querySnapshot.forEach(doc => {
//             // collect used segment IDs in array
//             currentSegmentIDs.push(doc.data().ID)
//         });
//     });

//     /**
//      * new geoSegment ID needs to be one high than the highest existing geo segment id
//      * 
//      */
//     let sortedGeoSegmentIDs = currentSegmentIDs.sort((a,b) => b - a ); // sorts the exisiting geo segment ids descending
//     newGeoSegmentID = sortedGeoSegmentIDs[0] + 1;
    
//     return newGeoSegmentID;
// }

function UploadSegment() {
    const [segment, setSegment] = useState(null);
    const [url, setUrl] = useState('');
    const [progress, setProgress] = useState(0);

    const handleChange = function(event){
        let segment = null;
        if (event.target.files[0]) {
            segment = event.target.files[0];
        }
        setSegment(segment);
    }

    const handleUpload = async function(segment){
        const storageRef = ref(storage, `/Geo-Segments/${segment.name}`);
        const uploadTask = uploadBytesResumable(storageRef, segment);

        // add reference of geo segment to firestore
        const lastDotIndex = segment.name.lastIndexOf(".");
        const segmentName = segment.name.substring(0, lastDotIndex);
        const timestamp = Date.now();

        uploadTask.on('state_changed',
            (snapshot) => {
                // progress function ....
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgress(progress);
            },
            (error) => {
                // error function ....
                console.log(error);
            },
            () => {
                // complete function ....
                getDownloadURL(storageRef).then(url => {
                    console.info('Datei erfolgreich hochgeladen.');
                    setUrl(url);

                    // Function for simultaneous upload to Firestore
                    setDoc(doc(collection(db, "geo-segments")), {
                        Name: segmentName,
                        ID: null,
                        GID: "",
                        syncedAt: -1,
                        Path: 'gs://Geo-Segments/' + segmentName + '.json',
                        Category: "geo",
                        createdAt: timestamp,
                        lastChangedAt: timestamp,
                        style:
                            { // default style
                                "radius": 4 ,
                                "fillColor": "#f00",
                                "color": "#000",
                                "weight": 3,
                                "opacity": 1,
                                "fillOpacity": 0.6
                            },
                        Description: "Empty Description"
                    });
                });
                    console.log('Reload Map LayersControl');
            });
    }

    return (
            <div className={classes.uploadWindow}>
                <input className={classes.inputSegment} type="file" onChange={handleChange}/>
                <Button type="primary" onClick={() => {handleUpload(segment)}}>Hochladen</Button>
                <br/>
                <br/>
                <progress className={classes.progress} value={progress} max="100"/>
            </div>
    )
}
export default UploadSegment;