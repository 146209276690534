import React, {useEffect, useState, useRef} from 'react';
import {Map, LayersControl, TileLayer, GeoJSON, FeatureGroup} from 'react-leaflet';
import L from 'leaflet';
import './styles.css';
import 'leaflet/dist/leaflet.css';
import  'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css';

import firebase from '@firebase/app';
import '@firebase/firestore';
import '@firebase/auth';
import '@firebase/storage';

import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

// import MyStore from "../../config/store";

import { db, storage } from "../../config/firebase";

import { collection, getDoc, query, where, doc, getDocs } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";


 
import * as retinaIcon from 'leaflet/dist/images/marker-icon-2x.png';
import * as icon from 'leaflet/dist/images/marker-icon.png';
import * as markerShadow from 'leaflet/dist/images/marker-shadow.png';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: retinaIcon,
    iconUrl: icon,
    shadowUrl: markerShadow
});

const useGeoSegments = () =>{
    const [geoSegments, setGeoSegments] = useState([]);
    
    useEffect(() => {
        async function getSegments() {
        const q = query(collection(db, "geo-segments-dev"), where("Category", "==", "geo"));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(function(doc) {

            console.log(doc.id, " => ", doc.data());
            var pathToGeoJSON = doc.data().Path;
            if (pathToGeoJSON !== undefined && pathToGeoJSON !== "") {
                // get last part of the path for correct file name
                var geoJsonFileName = pathToGeoJSON.split("/")[pathToGeoJSON.split("/").length - 1];
                    const storage = getStorage();
                    getDownloadURL(ref(storage, `Geo-Segments/${geoJsonFileName}`))
                        .then(async url => {
                    
                    const response = await fetch(url);
                    const geojson = await response.json();
                    setGeoSegments(geoSegments => [
                        ...geoSegments, 
                        {   
                            'ID' : doc.data().ID,
                            'Name' : doc.data().Name,
                            'GeoJSONData' : geojson,
                            'style' : doc.data().style
                        }
                    ]);
                })
            }
        
        
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, " => ", doc.data());
        });
      }
      getSegments()
  },[]);
  return geoSegments;
};



function GeomanMap (props) {
    const center = [51.163361, 10.447683];
    const geoSegments = useGeoSegments();

    
    var mapRef = useRef();
    var filtersLayerRef = useRef();

    useEffect(() => {
        const { current = {} } = mapRef;
        const { leafletElement: map } = current;

        if (!map) return;

        var mapElement = mapRef.current.leafletElement;

        // set language to german (includes tooltips)
        mapElement.pm.setLang('de');

        mapElement.pm.addControls({
            drawMarker: false,
            drawCircleMarker: (props.userisAdmin || props.userisEditor) && props.isEditModeEnabled,
            drawCircle: props.userisAdmin && props.isEditModeEnabled,
            drawPolyline: false,
            drawText: false,
            drawRectangle: props.userisAdmin && props.isEditModeEnabled,
            drawPolygon: props.userisAdmin && props.isEditModeEnabled,
            editMode: props.userisAdmin && props.isEditModeEnabled,
            dragMode: props.userisAdmin && props.isEditModeEnabled,
            cutPolygon: false,
            removalMode: props.userisAdmin && props.isEditModeEnabled,
            oneBlock: true,
            rotateMode: props.userisAdmin && props.isEditModeEnabled
        });


        mapElement.pm.setGlobalOptions({ pmIgnore: false });
        filtersLayerRef.current = L.layerGroup().addTo(mapElement);
        mapElement.on('pm:create', function (e) {

        });
        mapElement.on('pm:remove', function () {
            // onCircleRemoved();
        });
    },)

    const createPopupContent = function(feature, layer){
        var output = [];
        if (feature.properties) {
            for (let key in feature.properties) {
                output.push(key + ": " + feature.properties[key]);
            }
            layer.bindPopup(output.join("<br />"));
        }
    };

    return (
        <Map  ref={mapRef} center={center} editMode={props.isEditModeEnabled} zoom={6}>
            <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <LayersControl position="topright" collapsed={true} >

                {geoSegments.map(segment => (
                        <LayersControl.Overlay name={segment.Name} key={segment.Name}>
                            <FeatureGroup onClick={() => {
                                //console.log(`Clicked on ${segment.Name} GeoSegment (${segment.ID})`);
                            }}>
                                <GeoJSON 
                                    key={segment.Name} 
                                    data={segment.GeoJSONData} 
                                    className="GeoSegment"
                                    style={segment.style}
                                    isEditModeEnabled = {props.isEditModeEnabled}
                                    pointToLayer={(feature, latlng) => {
                                        return L.circleMarker(latlng, {
                                            ...segment.style,
                                            "radius": feature.properties.radius
                                        });
                                    }}
                                    onEachFeature={(feature, layer) => (
                                        console.log("GeomanMap - isEditModeEnabled", props.isEditModeEnabled),

                                        createPopupContent(feature, layer),
                                        layer.on('click', event => {
                                            console.log("onClick", props.isEditModeEnabled)
                                            if(props.isEditModeEnabled){
                                                layer.pm.toggleEdit({
                                                    allowSelfIntersection: false,
                                                });
                                                console.log('Clicked Feature: ', event.target.feature.properties.Name)
                                            }
                                        }),
                                        layer.on('pm:edit', (e) => {
                                            console.log("A Layer was edited.", e);
                                        })
                                    )}
                                    >
                                </GeoJSON>
                            </FeatureGroup>
                        </LayersControl.Overlay>
                ))}
            </LayersControl>
        </Map>
    );
}

    /*
    const geoSegments = new L.GeoJSON(geoSegmentsData, {
        onEachFeature: (feature = {}, layer) => {
            const { properties = {} } = feature;
            const { Name, ID } = properties;

            if ( !Name ) return;

            layer.bindPopup(`<p>${Name},${ID}</p>`);
        }
    });
    */

export default GeomanMap;