import React from "react";
import "./style.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Login from "./components/Login/";

import { Layout, } from 'antd';


function App() {

  return (
    <Layout style={{ minHeight: '100vh' }}>
			<Layout>
            <Router>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<Home />} />
              </Routes>
            </Router>    
			</Layout>
		</Layout>
  );
}

export default App;